/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'TWK Lausanne', Helvetiva, sans-serif;
  background-color: #1b1e29;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

/* Wallet Connect v2 */
html body {
  --wcm-z-index: 251;
}
